import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Divider } from 'semantic-ui-react';
import styled from 'styled-components';
import Img from 'gatsby-image';

const StyledImg = styled(Img)`
  max-width: 12em;
  margin-bottom: 3em;
  margin-top: 3em;
  margin-left: auto;
  margin-right: auto;
`;

const BorderDiv = styled('div')`
  width: 100%;
  height: 0.2em;
  background-color: black;
`;

const StyledGridColumn = styled(Grid.Column)`
  min-width: 14.7% !important;
`;

const Judges = ({ data, bgimage }) => (
  <Grid
    relaxed
    style={{
      minHeight: '80vh',
      backgroundSize: 'auto 100%',
      backgroundImage: `url(${
        bgimage.image ? bgimage.image.childImageSharp.fluid.src : ''
      })`,
      backgroundColor: '#E3D5DA',
      paddingTop: '7vh',
      width: '100vw',
    }}
  >
    <span id="judge-section" className="anchor"></span>
    <Grid.Row columns="16" centered>
      <Grid.Column computer="12" textAlign="center">
        <h1>Meet The Judges</h1>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row columns="14" centered>
      {data.map((judge, i) => (
        <StyledGridColumn
          computer="2"
          mobile="8"
          key={`${i}-image`}
          verticalAlign="top"
        >
          <Grid>
            <Grid.Row columns="16">
              <Grid.Column computer="16" mobile="16">
                <StyledImg
                  fluid={judge.image ? judge.image.childImageSharp.fluid : {}}
                />
              </Grid.Column>
              <Grid.Column computer="16" mobile="16">
                <BorderDiv></BorderDiv>
                <h3>{judge.title}</h3>
                <h5>{judge.details}</h5>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </StyledGridColumn>
      ))}
    </Grid.Row>
    <Divider hidden />
  </Grid>
);

Judges.propTypes = {
  data: PropTypes.object,
  bgimage: PropTypes.data,
};

export default Judges;
