import React from 'react';
import { Grid, Card } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';

const StyledCard = styled(Card)`
  background-color: #f15f61 !important;
  box-shadow: 0px 0px 0px 1px #d4d4d500, 0px 2px 4px 0px rgba(34, 36, 38, 0.12),
    0px 2px 10px 0px rgba(34, 36, 38, 0.15) !important;
`;

const Winners = ({ data, bgimage }) => (
  <Grid
    style={{
      minHeight: '40vh',
      backgroundSize: 'auto 100%',
      backgroundImage: `url(
        ${bgimage.image ? bgimage.image.childImageSharp.fluid.src : ''})`,
      backgroundColor: '#E3D5DA',
      paddingTop: '6vh',
      paddingBottom: '5vh',
      width: '100vw',
    }}
  >
    <span id="winner-section" className="anchor"></span>
    <Grid.Row columns="16" centered>
      <Grid.Column computer="12" textAlign="center">
        <h1>Winners</h1>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row columns="16" centered>
      <Grid.Column computer="12" textAlign="left">
        <Card.Group itemsPerRow={3} stackable>
          {data.map((d, i) => (
            <StyledCard
              key={`card-${i}`}
              link
              raised
              onClick={() => window.open(d.link, '_blank')}
            >
              <Img fluid={d.image ? d.image.childImageSharp.fluid : {}} />
              <Card.Content>
                <Card.Header style={{ color: 'white' }}>
                  <h2>{d.title}</h2>
                </Card.Header>
                <Card.Meta style={{ color: '#ededed' }}>{d.subtitle}</Card.Meta>
                <Card.Description style={{ color: 'white' }}>
                  <h4>{d.descr}</h4>
                </Card.Description>
              </Card.Content>
            </StyledCard>
          ))}
        </Card.Group>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

Winners.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  bgimage: PropTypes.object,
};

export default Winners;
