import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { Grid, Divider } from 'semantic-ui-react';
import styled from 'styled-components';
import IO from '../../io';

const StyledHeartImage = styled(Img)`
  width: 9em;
  margin-bottom: 2em;
  margin-top: 2em;
  margin-left: auto;
  margin-right: auto;
  transition: all ease-in-out 0.7s;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  top: -${props => (props.isVisible ? 0 : props.displacement)}vh;
`;

const BorderDiv = styled('div')`
  width: 100%;
  height: 0.2em;
  background-color: black;
`;

const PaddedColumn = styled(Grid.Column)`
  margin-bottom: 3em !important;
`;

const Criteria = ({ data, bgimage }) => (
  <Grid
    style={{
      minHeight: '80vh',
      backgroundSize: 'auto 100%',
      backgroundImage: `url(
        ${bgimage.image ? bgimage.image.childImageSharp.fluid.src : ''})`,
      backgroundColor: '#E3D5DA',
      paddingTop: '7vh',
      width: '100vw',
    }}
  >
    <Grid.Row columns="16" centered>
      <Grid.Column computer="12" textAlign="center">
        <h1>Judging Criteria</h1>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row columns="16" centered>
      {data.map((criterion, i) => (
        <PaddedColumn key={i} computer="3" mobile="8" verticalAlign="top">
          <IO rootMargin={`-${-5 + (i + 1) * 2} 0% 0% 0%`}>
            {({ isVisible }) => (
              <StyledHeartImage
                displacement={(i + 1) * 15}
                isVisible={isVisible}
                fluid={
                  criterion.image ? criterion.image.childImageSharp.fluid : {}
                }
              />
            )}
          </IO>
          <BorderDiv></BorderDiv>
          <h2>{criterion.title}</h2>
          <h3>{criterion.descr}</h3>
        </PaddedColumn>
      ))}
    </Grid.Row>
    <Divider hidden />
  </Grid>
);

Criteria.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  bgimage: PropTypes.object,
};

export default Criteria;
