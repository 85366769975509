import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Sticky, Grid, Button, Responsive } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledSticky = styled(Sticky)`
  width: 100vw;
  height: 0;
  padding-left: 0 !important;
  padding-right: 0 !important;

  div.sticky {
    transition: top ease-in-out 0.3s;
    top: ${props => (props.isHidden ? '-20vh !important' : '0 !important')};
  }
`;

const StyledOuterGridRow = styled(Grid.Row)`
  background-image: linear-gradient(to right, #e0cfd6, #ccb0bb);
  width: 100vw;
  display: inline-block;
`;

const StyledLogoImage = styled(Img)`
  width: 5em;
  margin-left: 10%;
  margin-top: 2vh;
  margin-bottom: 2vh;
  position: relative;
  display: block;
  float: left;
`;

const StyledLink = styled('a')`
  float: right;
  font-size: 1.5em;
  display: block;
  padding-left: 2vw;
  padding-right: 2vw;
  margin-top: 2.5vw;
  color: black;

  &::after {
    content: '';
    display: block;
    width: 0;
    height: 0.2em;
    border-radius: 0.3em;
    margin-top: 0.5em;
    transition: width 0.3s;
    background: #000;
  }

  &:hover {
    color: black;
  }

  &:hover::after {
    width: 100%;
  }
`;

const StyledButton = styled(Button)`
  float: right;
  background-color: #f15f61 !important;
  color: white !important;
  font-size: 1.2em !important;
  border-radius: 0.5em !important;
  display: block;
  margin-left: 2em !important;
  margin-right: 2em !important;
  margin-top: 1.8em !important;

  &:hover {
    background-color: #cc4652 !important;
  }
`;

const StyledMobileButton = styled(Button)`
  background-color: #f15f61 !important;
  color: white !important;
  border-radius: 0.5em !important;
  font-size: 1.2em !important;
  display: block;
  margin-left: 2em !important;
  margin-right: 2em !important;
  margin-top: 1em !important;

  &:hover {
    background-color: #cc4652 !important;
  }
`;

const StyledBurgerButton = styled(Button)`
  float: right;
  font-size: 1.2em !important;
  border-radius: 0.5em !important;
  display: block;
  margin-left: 2em !important;
  margin-right: 2em !important;
  margin-top: 1.8em !important;
  background: lightcoral !important;
`;

const StyledMobileLink = styled('a')`
  display: block;
  font-size: 1.5em;
  padding-left: 2em;
  padding-right: 2em;
  margin-bottom: 1em;
  margin-top: 1em;
  color: black;
`;

const LinkContainer = styled('div')`
  margin-right: 5vw;
  display: block;
`;

const MobileLinkContainer = styled('div')`
  margin-right: 5vw;
  display: inline-block;
  height: inherit;
  overflow: hidden;
  width: 100vw;
  transition: max-height 0.9s ease-in-out;
  max-height: ${props => (props.isMenuOpen ? '500px' : '0')};
`;

const NavbarFixedColor = 'pink';
const NavbarMovingColor = 'white';
const isBrowser = () => typeof window !== 'undefined';

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false,
      navbarColor: NavbarFixedColor,
      lastScrollY: isBrowser() ? window.pageYOffset : 0,
      isScrollDown: false,
      isReadyToRender: false,
    };
  }

  componentDidMount() {
    if (isBrowser()) {
      window.addEventListener('scroll', this.handlePageScroll.bind(this), true);
      this.setState({
        isReadyToRender: true,
      });
    }
  }

  handlePageScroll() {
    this.setState(prevState => {
      if (prevState.lastScrollY > window.scrollY) {
        return {
          lastScrollY: window.scrollY,
          isScrollDown: false,
        };
      } else {
        return {
          lastScrollY: window.scrollY,
          isScrollDown: true,
        };
      }
    });
  }

  toggleMenuOpen() {
    this.setState(prevState => {
      return {
        isMenuOpen: !prevState.isMenuOpen,
      };
    });
  }

  toggleStickyNavbar(color) {
    this.setState({
      navbarColor: color,
    });
  }

  render() {
    const { passedRef, logo } = this.props;
    const { isMenuOpen, isScrollDown, isReadyToRender } = this.state;
    return (
      <StyledSticky
        isHidden={isScrollDown}
        context={passedRef}
        onTop={() => this.toggleStickyNavbar(NavbarFixedColor)}
        onStick={() => this.toggleStickyNavbar(NavbarMovingColor)}
      >
        <StyledOuterGridRow>
          <Grid.Column width="12" style={{ paddingBottom: 0 }}>
            <a
              href="http://www.xds.humancentreddata.science/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StyledLogoImage fluid={logo ? logo.childImageSharp.fluid : {}} />
            </a>
            {isReadyToRender
              ? [
                  <Responsive minWidth={650} key="navbar-desktop">
                    <LinkContainer>
                      <StyledButton
                        onClick={() =>
                          window.open(
                            'https://forms.gle/mCq47hUTQfu93Dz1A',
                            '_blank'
                          )
                        }
                      >
                        Register
                      </StyledButton>
                      <StyledLink href="#faq-section">FAQs</StyledLink>
                      <StyledLink href="#judge-section">Judging</StyledLink>
                      <StyledLink href="#challenge-section">
                        Challenge
                      </StyledLink>
                      <StyledLink href="#winner-section">Winners</StyledLink>
                    </LinkContainer>
                  </Responsive>,
                  <Responsive maxWidth={649} key="navbar-mobile">
                    <StyledBurgerButton
                      onClick={() => this.toggleMenuOpen()}
                      icon={isMenuOpen ? 'x' : 'bars'}
                    />
                    <MobileLinkContainer isMenuOpen={isMenuOpen}>
                      <StyledMobileButton
                        onClick={() =>
                          window.open(
                            'https://forms.gle/mCq47hUTQfu93Dz1A',
                            '_blank'
                          )
                        }
                      >
                        Register
                      </StyledMobileButton>
                      <StyledMobileLink href="#faq-section">
                        Guidelines
                      </StyledMobileLink>
                      <StyledMobileLink href="#judge-section">
                        Timeline
                      </StyledMobileLink>
                      <StyledMobileLink href="#winner-section">
                        Winners
                      </StyledMobileLink>
                      <StyledMobileLink href="#challenge-section">
                        Judging Criteria
                      </StyledMobileLink>
                    </MobileLinkContainer>
                  </Responsive>,
                ]
              : null}
          </Grid.Column>
        </StyledOuterGridRow>
      </StyledSticky>
    );
  }
}

Navbar.propTypes = {
  logo: PropTypes.object,
  passedRef: PropTypes.object,
};

export default Navbar;
