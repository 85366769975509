import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import IO from '../../io';

const BorderDiv = styled('div')`
  width: 60%;
  height: 0.2em;
  background-color: black;
`;

const StyledImage = styled(Img)`
  margin-bottom: 2em;
  margin-left: auto;
  margin-right: auto;
  transition: all ease-in-out 0.7s;
  top: ${props => (props.isVisible ? '0vh' : '-20vh')};
  opacity: ${props => (props.isVisible ? 1 : 0)};
`;

const Prizes = ({ data, prizeImage, bgimage }) => (
  <Grid
    style={{
      minHeight: '90vh',
      backgroundSize: 'auto 100%',
      backgroundImage: `url(${
        bgimage.image ? bgimage.image.childImageSharp.fluid.src : ''
      })`,
      backgroundColor: '#E0CED5',
      paddingTop: '7vh',
      width: '100vw',
    }}
  >
    <span id="prize-section" className="anchor"></span>
    <Grid.Row columns="16" centered>
      <Grid.Column computer="12" textAlign="center">
        <h1>Prizes</h1>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row columns="16" centered reversed="computer">
      <Grid.Column computer="3" mobile="12" verticalAlign="middle">
        <Img
          style={{
            maxWidth: '12em',
            marginBottom: '5em',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          fluid={prizeImage.image ? prizeImage.image.childImageSharp.fluid : {}}
        />
      </Grid.Column>
      {data.map((prize, i) => (
        <Grid.Column
          computer="3"
          mobile="16"
          key={`${i}-image`}
          verticalAlign="bottom"
        >
          <Grid>
            <Grid.Row columns="16">
              <Grid.Column computer="16" mobile="8">
                <IO rootMargin={`-${10 + (i + 1) * 2}% 0% 0% 0%`}>
                  {({ isVisible }) => (
                    <StyledImage
                      isVisible={isVisible}
                      style={{ maxWidth: prize.width, maxHeight: '32vh' }}
                      fluid={
                        prize.image ? prize.image.childImageSharp.fluid : {}
                      }
                      imgStyle={{ objectFit: 'scale-down' }}
                    />
                  )}
                </IO>
              </Grid.Column>
              <Grid.Column computer="16" mobile="8">
                <BorderDiv></BorderDiv>
                <h2 style={{ textAlign: 'left' }}>
                  {prize.title}
                  <br></br>
                  {prize.details}
                </h2>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      ))}
    </Grid.Row>
  </Grid>
);

Prizes.propTypes = {
  data: PropTypes.object,
  prizeImage: PropTypes.object,
  bgimage: PropTypes.data,
};

export default Prizes;
