import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Divider } from 'semantic-ui-react';
import Countdown from 'react-countdown-now';
import styled from 'styled-components';
import MapAnimation from '../../MapAnimation';

const Completionist = () => (
  <React.Fragment>
    <Divider hidden />
    <h2>Submissions have now closed! Thanks for your interest</h2>
  </React.Fragment>
);
const StyledBox = styled('div')`
  background-color: #e0ced5;
  border-radius: 0.3em;
  padding: 0.7em;
  width: 100%;
  text-align: center;
`;
const StyledH5 = styled('h5')`
  margin-top: -0.9em;
`;

const StyledColumn = styled(Grid.Column)`
  padding-right: 0.2em !important;
  padding-left: 0.2em !important;
`;

const forceDoubleDigit = num => ('0' + num).slice(-2);
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <Completionist />;
  }

  return (
    <Grid centered>
      <Divider hidden />
      <Grid.Row centered columns="16">
        <h3>Deadline To Submit Extended Until</h3>
      </Grid.Row>
      <Grid.Row columns="16">
        <StyledColumn width="4">
          <StyledBox>
            <h1>{forceDoubleDigit(days)}</h1>
            <StyledH5>Days</StyledH5>
          </StyledBox>
        </StyledColumn>
        <StyledColumn width="4">
          <StyledBox>
            <h1>{forceDoubleDigit(hours)}</h1>
            <StyledH5>Hours</StyledH5>
          </StyledBox>
        </StyledColumn>
        <StyledColumn width="4">
          <StyledBox>
            <h1>{forceDoubleDigit(minutes)}</h1>
            <StyledH5>Minutes</StyledH5>
          </StyledBox>
        </StyledColumn>
        <StyledColumn width="4">
          <StyledBox>
            <h1>{forceDoubleDigit(seconds)}</h1>
            <StyledH5>Seconds</StyledH5>
          </StyledBox>
        </StyledColumn>
      </Grid.Row>
    </Grid>
  );
};

renderer.propTypes = {
  days: PropTypes.number,
  hours: PropTypes.hours,
  minutes: PropTypes.minutes,
  seconds: PropTypes.seconds,
  completed: PropTypes.bool,
};

const Banner = ({ data }) => (
  <Grid
    style={{
      minHeight: '107vh',
      backgroundImage: 'linear-gradient(to right, #E0CFD6, #CCB0BB)',
      paddingTop: '12.5vh',
      width: '100vw',
    }}
  >
    <Grid.Row columns="16" centered>
      <Grid.Column computer="8" mobile="16">
        <MapAnimation data={data} />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row columns="16" centered>
      <Grid.Column computer="8" mobile="16" verticalAlign="bottom">
        <h2 style={{ textAlign: 'left' }}>
          Find your statistically significant other
        </h2>
        <h2 style={{ textAlign: 'left' }}>
          A data storytelling challenge on the topic of{' '}
          <span style={{ color: '#ED1C24' }}>LOVE.</span>
        </h2>
      </Grid.Column>
      <Grid.Column computer="4" mobile="16" textAlign="left">
        <Countdown date={new Date(2019, 10, 4)} renderer={renderer} />
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

Banner.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

export default Banner;
