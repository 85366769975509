import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { geoMercator } from 'd3';
import anime from 'animejs/lib/anime.es';

const projection = geoMercator()
  .scale(60000)
  .center([104.05007, 1.31967]);

class MapAnimation extends Component {
  componentDidMount() {
    anime({
      targets: '.map-hearts',
      scale: [
        { value: 1.3, easing: 'easeOutSine', duration: 200 },
        { value: 1, easing: 'easeInOutQuad', duration: 500 },
      ],
      delay: anime.stagger(50, { grid: [12, 200], from: 'first' }),
      loop: true,
    });
  }

  render() {
    const { data } = this.props;
    const points = data.map((point, i) => {
      const coordinates = point.geometry.coordinates;
      return (
        <g
          key={i}
          transform={`translate(${projection(coordinates)[0]},${
            projection(coordinates)[1]
          })scale(0.3)`}
        >
          <path
            className="map-hearts"
            d="M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2
                c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"
            fill="#ED1C24"
          />
        </g>
      );
    });

    return (
      <svg width="100%" height="100%" viewBox="0 0 530 370">
        {points}
      </svg>
    );
  }
}

MapAnimation.propTypes = {
  data: PropTypes.object,
};

export default MapAnimation;
