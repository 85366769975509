import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { Grid, Button, Divider } from 'semantic-ui-react';
import styled from 'styled-components';
import IO from '../../io';

const StyledFAQImage = styled(Img)`
  width: 15vh;
  height: 15vh;
  vertical-align: middle;
  display: inline-block;
  transition: all ease-in-out 1s;
  opacity: ${props => (props.isVisible ? 1 : 0)};
`;

const StyledFAQImageBackground = styled('div')`
  background-color: #e2a1a1;
  border-radius: 1.7em;
  padding: 1.5em;
  height: 20vh;
  width: 20vh;
  margin-bottom: 1em;
  margin-top: 2em;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;

const StyledFAQButton = styled(Button)`
  border-radius: 0.6em !important;
  color: white !important;
  background-color: #f15f61 !important;

  &:hover {
    background-color: #cc4652 !important;
  }

  &:disabled {
    background-color: grey !important;
    color: lightgrey !important;
  }
`;

const HowTo = ({ data, bgimage }) => (
  <Grid
    style={{
      minHeight: '60vh',
      backgroundSize: 'auto 100%',
      backgroundImage: `url(${
        bgimage.image ? bgimage.image.childImageSharp.fluid.src : ''
      })`,
      backgroundColor: '#E0CED5',
      paddingTop: '7vh',
      width: '100vw',
    }}
  >
    <span id="faq-section" className="anchor"></span>
    <Grid.Row columns="16" centered>
      <Grid.Column computer="12" textAlign="center">
        <h1>FAQs</h1>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row columns="16" centered>
      {data.map((faq, i) => (
        <Grid.Column key={i} computer="3" mobile="8" verticalAlign="middle">
          <Grid>
            <Grid.Row columns="16">
              <Grid.Column computer="16" mobile="16" textAlign="center">
                <StyledFAQImageBackground>
                  <IO rootMargin={`-${-5 + (i + 1) * 2} 0% 0% 0%`}>
                    {({ isVisible }) => (
                      <StyledFAQImage
                        fluid={faq.image ? faq.image.childImageSharp.fluid : {}}
                        imgStyle={{ objectFit: 'scale-down' }}
                        isVisible={isVisible}
                      />
                    )}
                  </IO>
                </StyledFAQImageBackground>
              </Grid.Column>
              <Grid.Column computer="16" mobile="16" textAlign="center">
                <StyledFAQButton
                  onClick={() => window.open(faq.link, '_blank')}
                  disabled={faq.disabled}
                >
                  <h3>{faq.title}</h3>
                </StyledFAQButton>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      ))}
    </Grid.Row>
    <Divider hidden />
  </Grid>
);

HowTo.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  bgimage: PropTypes.object,
};

export default HowTo;
