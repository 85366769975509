import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Divider } from 'semantic-ui-react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import IO from '../../io';

const StyledImage = styled(Img)`
  height: 30vh;
  margin-top: 3em;
  margin-bottom: 2em;
  transition: all ease-in-out 0.7s;
  transform: ${props => (props.isVisible ? 'scale(1)' : 'scale(0.1)')};
  opacity: ${props => (props.isVisible ? 1 : 0)};
`;

const StyledColumn = styled(Grid.Column)`
  margin-top: 3em !important;
`;

const Challenge = ({ data, bgimage }) => (
  <Grid
    style={{
      minHeight: '80vh',
      backgroundSize: 'auto 100%',
      backgroundImage: `url(${
        bgimage.image ? bgimage.image.childImageSharp.fluid.src : ''
      })`,
      backgroundColor: '#E0CED5',
      paddingTop: '7vh',
      width: '100vw',
    }}
  >
    <span id="challenge-section" className="anchor"></span>
    <Grid.Row columns="16" centered>
      <Grid.Column computer="6" mobile="16">
        <h1>
          The challenge is about impactful data stories around the theme of
          LOVE.
        </h1>
        <h3>
          Winning entries will paint a moving picture of the behaviours, desires
          and struggles hidden within the numbers.
        </h3>
      </Grid.Column>
      <Grid.Column computer="6" mobile="16">
        <IO rootMargin="-20% 0% 0% 0%">
          {({ isVisible }) => (
            <StyledImage
              fluid={data.image ? data.image.childImageSharp.fluid : {}}
              imgStyle={{ objectFit: 'scale-down' }}
              isVisible={isVisible}
            />
          )}
        </IO>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row columns="16" centered>
      <StyledColumn computer="6" mobile="16">
        <h1>Open to all.</h1>
        <h3>
          Entries can be in any format: <br></br>
          <ul>
            <li>Infographic</li>
            <li>Interactive</li>
            <li>Illustrated</li>
            <li>Digital or Physical</li>
          </ul>
        </h3>
      </StyledColumn>
      <StyledColumn computer="6" mobile="16">
        <h1>
          Find a story that interests you and visualise in your favourite
          medium.
        </h1>
        <h3>Think, introspect and empathise with the data.</h3>
        <h3>
          We’ve prepared a range of datasets tackling different aspects of LOVE
          in Singapore: top searches, marriage rates, attitudes to love... Or,
          collect your own personal dataset.
        </h3>
      </StyledColumn>
    </Grid.Row>
    <Divider hidden />
  </Grid>
);

Challenge.propTypes = {
  data: PropTypes.object,
  bgimage: PropTypes.object,
};

export default Challenge;
