import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledSponsorImage = styled(Img)`
  margin-top: 2em;
  margin-bottom: 2em;
  margin-left: 0;
  margin-right: 0;
  max-height: 5em;
  max-width: 16em;
  filter: grayscale(100%);

  &:hover {
    filter: grayscale(0%);
  }
`;
const Sponsors = ({ data }) => (
  <Grid
    style={{ minHeight: '20vh', backgroundColor: '#E3D5DA', width: '100vw' }}
    centered
  >
    <Grid.Row columns="16">
      {data.map((sponsor, i) => (
        <Grid.Column
          key={i}
          computer="3"
          mobile="8"
          verticalAlign="middle"
          centered
        >
          <a href={sponsor.link} target="_blank" rel="noopener noreferrer">
            <StyledSponsorImage
              imgStyle={{ objectFit: 'scale-down' }}
              fluid={sponsor.image ? sponsor.image.childImageSharp.fluid : {}}
            />
          </a>
        </Grid.Column>
      ))}
    </Grid.Row>
  </Grid>
);

Sponsors.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

export default Sponsors;
