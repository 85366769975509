import React from 'react';
import { Grid, Divider } from 'semantic-ui-react';

const Footer = () => (
  <Grid
    style={{ minHeight: '8vh', backgroundColor: '#E0CED5', width: '100vw' }}
  >
    <Divider hidden />
    <Grid.Row columns="16" centered>
      <Grid.Column computer="12" textAlign="center">
        <h5>
          Copyright XDS 2019<br></br>Contact Us: xds@humancentreddata.science
        </h5>
      </Grid.Column>
    </Grid.Row>
    <Divider hidden />
  </Grid>
);

export default Footer;
