import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import { graphql } from 'gatsby';
import { Container, Grid } from 'semantic-ui-react';
import styled from 'styled-components';

import Banner from '../components/Sections/Banner';
import Criteria from '../components/Sections/Criteria';
import HowTo from '../components/Sections/HowTo';
import Prizes from '../components/Sections/Prizes';
import Timeline from '../components/Sections/Timeline';
import Sponsors from '../components/Sections/Sponsors';
import Navbar from '../components/Sections/Navbar';
import Challenge from '../components/Sections/Challenge';
import Judges from '../components/Sections/Judges';
import Footer from '../components/Sections/Footer';
import Winners from '../components/Sections/Winners';

const StyledContainer = styled(Container)`
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  width: 100vw !important;
`;

class Index extends React.Component {
  contextRef = createRef();
  render() {
    const { data } = this.props;
    return (
      <div ref={this.contextRef}>
        <Layout>
          <StyledContainer>
            <Grid centered>
              <Navbar
                passedRef={this.contextRef}
                logo={data.homeJson.images.logo.src}
              />
              <Banner data={data.homeJson.features} />
              <Sponsors data={data.homeJson.sponsors} />
              <Challenge
                data={data.homeJson.challenge}
                bgimage={data.homeJson.bgimage.challenge}
              />
              <Winners
                data={data.homeJson.winners}
                bgimage={data.homeJson.bgimage.winners}
              />
              <Prizes
                data={data.homeJson.prizes}
                prizeImage={data.homeJson.prize}
                bgimage={data.homeJson.bgimage.prizes}
              />
              <Judges
                data={data.homeJson.judges}
                bgimage={data.homeJson.bgimage.judges}
              />
              <Criteria
                data={data.homeJson.criteria}
                bgimage={data.homeJson.bgimage.criteria}
              />
              <Timeline
                data={data.homeJson.timeline}
                timelineDesktopImage={data.homeJson.timelineDesktopImage}
                timelineMobileImage={data.homeJson.timelineMobileImage}
                bgimage={data.homeJson.bgimage.timeline}
              />
              <HowTo
                data={data.homeJson.howto}
                bgimage={data.homeJson.bgimage.howto}
              />
              <Footer />
            </Grid>
          </StyledContainer>
        </Layout>
      </div>
    );
  }
}

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      name
      features {
        geometry {
          coordinates
        }
      }
      images {
        logo {
          src {
            childImageSharp {
              fluid(maxHeight: 500, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      criteria {
        title
        descr
        image {
          childImageSharp {
            fluid(maxHeight: 400, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      prizes {
        title
        details
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        width
      }
      prize {
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      sponsors {
        link
        image {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      challenge {
        image {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      judges {
        title
        details
        image {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      timeline {
        title
        details
      }
      timelineDesktopImage {
        image {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      timelineMobileImage {
        image {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      howto {
        title
        disabled
        link
        image {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      winners {
        title
        subtitle
        descr
        image {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        link
      }
      bgimage {
        challenge {
          image {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        winners {
          image {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        criteria {
          image {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        judges {
          image {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        prizes {
          image {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        timeline {
          image {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        howto {
          image {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
