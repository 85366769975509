import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { Grid, Divider, Responsive } from 'semantic-ui-react';
import styled from 'styled-components';
import IO from '../../io';

const isBrowser = () => typeof window !== 'undefined';

const StyledHeartImage = styled(Img)`
  width: 65vw;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2em;
  margin-top: 4em;
  transition: all 0.7s ease-in-out;
  right: ${props => (props.isVisible ? '0vh' : '-30vw')};
  opacity: ${props => (props.isVisible ? 1 : 0)};
`;

const StyledMobileHeartImage = styled(Img)`
  max-height: 95vh;
  transition: all 0.7s ease-in-out;
  left: ${props => (props.isVisible ? '0vh' : '-30vw')};
  opacity: ${props => (props.isVisible ? 1 : 0)};
`;

const BorderDiv = styled('div')`
  width: 90%;
  height: 0.2em;
  background-color: black;
  margin: 0 auto;
`;

const StylizedGridColumn = styled(Grid.Column)`
  margin-top: 3em !important;
`;

class Timeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReadyToRender: false,
    };
  }

  componentDidMount() {
    if (isBrowser()) {
      this.setState({
        isReadyToRender: true,
      });
    }
  }

  render() {
    const {
      data,
      timelineDesktopImage,
      timelineMobileImage,
      bgimage,
    } = this.props;
    const { isReadyToRender } = this.state;
    return (
      <Grid
        style={{
          minHeight: '70vh',
          backgroundSize: 'auto 100%',
          backgroundImage: `url(${
            bgimage.image ? bgimage.image.childImageSharp.fluid.src : ''
          })`,
          backgroundColor: '#E3D5DA',
          paddingTop: '7vh',
          width: '100vw',
        }}
      >
        <Grid.Row columns="16" centered>
          <Grid.Column computer="12" textAlign="center">
            <h1>Timeline</h1>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="16" centered>
          <Grid.Column computer="16" mobile="12" tablet="16">
            <Grid centered>
              <Grid.Row columns="16">
                {data.map((date, i) => (
                  <StylizedGridColumn
                    key={i}
                    computer="3"
                    mobile="16"
                    tablet="3"
                    verticalAlign="bottom"
                  >
                    <h2>{date.title}</h2>
                    <BorderDiv></BorderDiv>
                    <h3>{date.details}</h3>
                  </StylizedGridColumn>
                ))}
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column
            computer="16"
            mobile="4"
            tablet="16"
            verticalAlign="bottom"
          >
            {isReadyToRender ? (
              <IO rootMargin="20% 0% 0% 0%">
                {({ isVisible }) => (
                  <span>
                    <Responsive maxWidth={649}>
                      <StyledMobileHeartImage
                        fluid={
                          timelineMobileImage.image
                            ? timelineMobileImage.image.childImageSharp.fluid
                            : {}
                        }
                        imgStyle={{ objectFit: 'scale-down' }}
                        isVisible={isVisible}
                      />
                    </Responsive>
                    <Responsive minWidth={650}>
                      <StyledHeartImage
                        fluid={
                          timelineDesktopImage.image
                            ? timelineDesktopImage.image.childImageSharp.fluid
                            : {}
                        }
                        imgStyle={{ objectFit: 'scale-down' }}
                        isVisible={isVisible}
                      />
                    </Responsive>
                  </span>
                )}
              </IO>
            ) : null}
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
      </Grid>
    );
  }
}

Timeline.propTypes = {
  data: PropTypes.object,
  bgimage: PropTypes.object,
  timelineDesktopImage: PropTypes.object,
  timelineMobileImage: PropTypes.object,
};

export default Timeline;
